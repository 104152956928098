/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

require("react-bnb-gallery/dist/style.css")
require("animate.css")
require("lazysizes")
const wow = require("wowjs")

exports.onRouteUpdate = () => {
  new wow.WOW({
    live: false,
    mobile: false,
    animateClass: "animate__animated",
  }).init()
}
